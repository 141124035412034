<template>
  <div class="columns form is-mini-gap is-multiline">
    <div class="column is-2">
      <InputProperty
        v-model:value="propertyId"
        :size="size"
        displayed-text="abbreviation"
        style="width: 100%"
        @change="handlePropertyChange"
      />
    </div>
    <div class="column is-2">
      <InputRoomType
        v-model:value="roomTypeId"
        :disabled="currentProperty && !currentProperty.useRoomType"
        :size="size"
        :property-id="propertyId"
        style="width: 100%"
        @change="handleRoomTypeChange"
      />
    </div>
    <div class="column is-2">
      <InputRoom
        v-model:value="roomId"
        :property-id="propertyId"
        :room-type-id="roomTypeId"
        return-value="code"
        :size="size"
        style="width: 100%"
        @change="handleRoomChange"
      />
    </div>
    <div class="column is-12 is-paddingless" />
    <div class="column is-2">
      <a-input
        v-model:value="reservationNumber"
        :size="size"
        :placeholder="$t('Reservation ID')"
        @change="debounceQuery"
      />
    </div>
    <div class="column is-2">
      <a-select
        v-model:value="status"
        :size="size"
        :placeholder="$t('Status')"
        style="width: 100%"
        @change="debounceQuery"
      >
        <a-select-option v-for="item in statusList" :key="item.label" :value="item.value">
          {{ $t(item.value) }}
        </a-select-option>
      </a-select>
    </div>
    <div class="column is-narrow reservations__date-filter">
      <a-input-group compact>
        <a-select
          v-model:value="dateSelection"
          class="is-mobile-field"
          :placeholder="$t('Choose date')"
          style="max-width: 110px"
          :size="size"
          @change="debounceQuery"
        >
          <a-select-option
            v-for="o in dateOptions"
            :key="$t(o.label)"
            :value="o.value"
            :title="$t(o.label)"
          >
            {{ $t(o.label) }}
          </a-select-option>
        </a-select>
        <a-range-picker
          v-model:value="range"
          class="is-mobile-field"
          :size="size"
          style="max-width: 200px"
          :format="dateFormat"
          @change="debounceQuery"
        >
          <a-input
            :value="rangeString"
            :placeholder="$t(`${dateSelectionText} range`)"
            :size="size"
            readonly
          >
            <template #prefix>
              <CalendarFilled />
            </template>
          </a-input>
        </a-range-picker>
      </a-input-group>
    </div>
    <div class="column is-2">
      <a-button
        v-show="propertyId || roomTypeId || roomId || date
          || status || reservationNumber || range.length"
        :size="size"
        style="min-width: 110px"
        type="default"
        @click="handleClear"
      >
        Clear
      </a-button>
    </div>
  </div>
</template>

<i18n>
{
  "en": {
    "checkout": "Checkout",
    "checkin": "Checkin",
    "agencyBookDate": "Travel agency booking date",
    "Choose date": "Choose date",
    "checkout range": "Checkout range",
    "checkin range": "Checkin range",
    "agencyBookDate range": "Travel agency booking date range",
  },
  "ja": {
    "checkout": "チェックアウト",
    "checkin": "チェックイン",
    "agencyBookDate": "旅行会社予約受付日",
    "Choose date": "日付を選択",
    "checkout range": "チェックアウト範囲",
    "checkin range": "チェックイン範囲",
    "agencyBookDate range": "旅行会社予約受付日範囲",
  }
}
</i18n>

<script>
import debounce from 'lodash/debounce';
import { CalendarFilled } from '@ant-design/icons-vue';
import InputProperty from '@/components/InputProperty';
import InputRoomType from '@/components/InputRoomType';
import InputRoom from '@/components/InputRoom';
import { DATE_OPTIONS } from '@/config/reservations';
import { cleanObject } from '@/utils/util';

export default {
  name: 'SearchForm',
  components: {
    CalendarFilled,
    InputRoomType,
    InputRoom,
    InputProperty,
  },
  props: {
    size: {
      type: String,
      default: 'large',
    },
    dateFormat: {
      type: String,
      default: 'MM/DD/YYYY',
    },
  },
  emits: ['filter'],
  data() {
    return {
      currentProperty: undefined,
      dateSelection: undefined,
      dateOptions: DATE_OPTIONS,
      roomId: undefined,
      date: undefined,
      propertyId: undefined,
      roomTypeId: undefined,
      roomNumber: undefined,
      status: undefined,
      range: [],
      reservationNumber: undefined,
      statusList: [
        { label: 'Checked In', value: 'CHECKED_IN' },
        { label: 'Confirmed', value: 'CONFIRMED' },
        { label: 'Completed', value: 'COMPLETED' },
        { label: 'Cancelled', value: 'CANCELLED' },
      ],
    };
  },
  computed: {
    roomIdField() {
      if (this.roomTypeId) {
        return 'number';
      }

      return 'code';
    },
    rangeString() {
      if (!this.range || this.range.length === 0) {
        return '';
      }
      const [from, to] = this.range;
      const opts = { format: this.dateFormat };

      return `${this.$filters.date(from, opts)} ~ ${this.$filters.date(to, opts)}`;
    },
    formattedRange() {
      if (!this.range || this.range.length === 0) {
        return undefined;
      }

      const [from, to] = this.range;
      const opts = { format: 'YYYY-MM-DD' };

      return `${this.$filters.date(from, opts)},${this.$filters.date(to, opts)}`;
    },
    dateSelectionText() {
      if (this.dateSelection) {
        return this.dateOptions.find((object) => object.value === this.dateSelection).label;
      }
      return 'Date';
    },
  },
  mounted() {
    this.dateSelection = this.getDateSelection();
    this.range = this.$route.query[this.dateSelection]
      ? this.$route.query[this.dateSelection].split(',')
      : [];

    this.propertyId = this.$route.query.propertyId || undefined;
    this.roomTypeId = this.$route.query.roomTypeId || undefined;
    this.roomId = this.$route.query.roomId || undefined;
    this.reservationNumber = this.$route.query.reservationNumber || undefined;
    this.status = this.$route.query.status ? this.$route.query.status.split(',') : undefined;
  },
  methods: {
    handlePropertyChange(selected) {
      const currentUseRoomType = Boolean(this.currentProperty && this.currentProperty.useRoomType);

      if (currentUseRoomType && !selected.useRoomType) {
        this.roomTypeId = undefined;
        this.roomNumber = undefined;
        this.roomId = undefined;
      }

      if (
        this.currentProperty
        && this.currentProperty.newPropertyCode !== selected.newPropertyCode
      ) {
        this.roomNumber = undefined;
        this.roomId = undefined;
      }

      this.propertyId = selected.newPropertyCode;
      this.currentProperty = selected;

      this.debounceQuery();
    },
    handleRoomTypeChange(id) {
      this.roomTypeId = id;

      if (this.roomTypeId) {
        this.roomNumber = undefined;
        this.roomId = undefined;
        this.debounceQuery();
      }
    },
    handleRoomChange(id, room) {
      this.roomId = id;

      if (this.roomTypeId) {
        this.roomNumber = room.number;
      }

      this.debounceQuery();
    },
    debounceQuery: debounce(async function debounceQuery() {
      const query = cleanObject({
        ...this.$route.query,
        propertyId: this.propertyId,
        roomTypeId: this.roomTypeId,
        roomNumber: this.roomNumber,
        roomId: this.roomId,
        reservationNumber: this.reservationNumber,
        status: this.status,
        page: 1, // force page 1
      });

      if (this.dateSelection && this.range.length) {
        query[this.dateSelection] = this.formattedRange;
        query.sortField = this.dateSelection;
        query.sortOrder = 'ASC';
      }

      await this.$router.push({ query });

      this.$emit('filter');
    }, 600),
    handleClear() {
      this.roomId = undefined;
      this.date = undefined;
      this.propertyId = undefined;
      this.roomTypeId = undefined;
      this.roomNumber = undefined;
      this.reservationNumber = undefined;
      this.status = undefined;
      this.range = [];
      this.dateSelection = undefined;
      this.debounceQuery();
    },
    getDateSelection() {
      const { checkin, checkout, agencyBookDate } = this.$route.query;

      if (checkin) {
        return 'checkin';
      }

      if (checkout) {
        return 'checkout';
      }

      if (agencyBookDate) {
        return 'agencyBookDate';
      }

      return undefined;
    },
  },
};
</script>

<style lang="scss" scoped>
.reservations__date-filter .ant-input-affix-wrapper {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
</style>
